

@font-face {
  font-family: 'CircularBlack';
  src: local('CircularBlack'), url(./fonts/Circular-Black.otf) format('otf');
  font-weight: black;
}

@font-face {
  font-family: 'CircularBold';
  src: local('CircularBold'), url(./fonts/Circular-Bold.otf) format('otf');
  font-weight: Bold;

}


@font-face {
  font-family: 'CircularMedium';
  src: local('CircularMedium'), url(./fonts/Circular-Medium.otf) format('otf');
  font-weight: Medium;

}


@font-face {
  font-family: 'CircularBook';
  src: local('CircularBook'), url(./fonts/Circular-Book.otf) format('otf');
  font-weight: Book;

}