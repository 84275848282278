.image__listing{
  width: 100%;
  height: 300px;
  object-fit: cover;


}

.map-container {
  
margin-top: 1rem;
display: flex;
justify-content: center;
height: 500px;
overflow:hidden;

}

.image__container {
  max-width: 100%;

}

.image-slider{
  width :200px;
  border-radius: 8px;
}

.def__rating{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.carousel-layout{
  min-width: 330px;
  max-width: 500px;
}

.carousel-container{
  width: 100%;

}

.container__title{
  width: 100%;
}
.main__description{

  width: 100%;
  display: flex;
  flex-direction: column-reverse;

}


.title__listing{
  margin-left: 1rem;
  font-weight: 600;
}


.slogan__listing{
margin-left: 1rem;
margin-right: 1rem;
display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

}

.divider{
  margin-top: 2rem;
  width: 95%;
}



.ratings {
  display: flex;
  margin: 1rem;
  font-weight: 800;
}

.number {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
}

.address .rating {
display: inline-flex;
align-items: middle;


}

.location__icon .MuiSvgIcon-root  {
  width: .7em;
  padding-bottom: 3px;
}

.rating__icon .MuiSvgIcon-root {
  width: .7em;
  padding-bottom: 3px;
  color: #ff2e76;

}
.location__address:hover {
  text-decoration: underline;
  cursor: pointer;
}

.peculiarities__listing{
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.peculiarities__icons .MuiSvgIcon-root{

 width: .7em;
}
.peculiarities{
  display: flex;
  margin-top: 1rem;
}

.pec__explaining{
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
}

.peculiarities__resume {
  font-size: 12px;
  margin-left: 2px;
  color: #717171;
}

.peculiarities__title {
  font-weight: 600;
}
.peculiarities__title__green{
  color: #00A699;
  font-weight: 600;
  text-decoration: underline;

}

.title{
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  max-height: 35px;
  margin-top: 2rem;
}

.rating__icon {
  width: .7em;
  padding-bottom: 3px;
  color: #ff2e76;
  margin-bottom: 2px;
}

.verified__icon{
  color: #ff2e76;
}

.peculiarities__title__primary{
  color: #ff2e76;
  font-weight: 600;
}

.description__listing{
  padding: 1rem;
}

.description__title{
  font-weight: 600;
}

.price{
  font-weight: 800;

}

.notification {
  margin: 1rem;
  border: 1px solid #717171;
  border-radius: 5px;
  padding: 1rem;
  text-justify: center;
  color: #717171;

}

.notification__icon {
  margin-right: 5px;
  color: #717171;
}

.container__listing{
  max-width: 1200px;
  margin: 0 auto;

}
.container__file{

margin: 0 auto;
}

.booking-container {
  margin: 1rem;
}

.button-booking{
  padding: 10px 20px;
  border: none;
  background-color: #ff2e76;
  border-radius: 5px;
  color: white;
  font-weight: 800;
  width: 100%;

}

.comments{
  color: #00A699;
  border-bottom: 1px solid #00A699;
  font-weight: bolder;
  margin-left: 20px;
  margin-bottom: 5px;
}



.comment:hover{
  cursor: pointer;
}

.comments-container{
  display: flex;
  justify-content: space-around;
}

@media (min-width: 600px) {

  .image__listing{
    height: 100%;
    max-width: 550px;
    border-radius: 10px;
  }

  .image__container {
    max-width: 100%;


  }
}

@media (min-width: 600px) {

  .image__listing{
    width: 450px;
    height: 450px
  }
  .file__listing{
    display: flex;
    margin-top: 2rem;
  }

  .peculiarities__listing{
    height: 100%;
    border-radius: 5px;
  }

  .container__title{
    height: 100%;
    max-width: 350px;

  }

  .title__listing{
    margin-top: 1rem;

  }
  .peculiarities__resume{
    font-size: 10px;
  }

  .container__description{
    margin: 0 2rem 0 2rem;
  }

}

@media (min-width: 700px) {
  .image__container {
    display: flex;
    margin: 0 auto;
    max-width: 100%;
    justify-content: space-around;
    padding: 2rem;

  }

}

@media (min-width: 990px) {
  .container__file{
    width: 90%;
  }
}

@media (max-width: 900px) {
  .image__container{
    max-width: 100%;
  }

}


@media (max-width: 700px) {
  .image__listing{
    max-width: 100%;
  }

  .title__listing{
    margin-top: 1rem;

  }


  .container__title{
    max-width:100%;
  }

}

@media (max-width: 400px) {

  .comment-box{

    display: flex;
    flex-direction: column-reverse;
  }
}