.container-titles {
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  margin-top: 2rem;

}

.title-experience{
  padding: 1rem;
  font-weight: 600;

}

.title-experience h4{
  font-weight:600;
  
}


.image__listing{
  width: 100%;
  height: 300px;
  object-fit: cover;
}



.image-slider{
  width :200px;
  border-radius: 8px;
}

.def__rating{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.carousel-layout{
  min-width: 330px;
  max-width: 500px;
}

.experience-carousel{
  width: 100%;

}

.container_pricebox {
  position: relative ;
  width: 33% ;
  margin-right: 3% ;
  margin-left: 3% ;
}

.pricebox{
  position: -webkit-sticky ;
  position: sticky ;
  top: var(--navigation-bar-offset, 80px) ;
  z-index: 1 ;
  width: 100% ;
  display: inline-block ;
  padding-top: 2rem ;
}
.main__description{
  width: 100%;
  display: flex;
  flex-direction: column;

}


.title__listing{
  margin-left: 1rem;
  font-weight: 800;
}


.slogan__listing{
  margin-left: 1rem;
  margin-right: 1rem;
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

.divider{
  margin-top: 2rem;
  width: 95%;
}



.experience_ratings {
  display: flex;
  margin-left: 1rem;
  font-weight: 800;
}

.number {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
}

.address .rating {
  display: inline-flex;
  align-items: middle;


}

.location__icon .MuiSvgIcon-root  {
  width: .7em;
  padding-bottom: 3px;
}

.rating__icon .MuiSvgIcon-root {
  width: .7em;
  padding-bottom: 3px;
  color: #ff2e76;

}
.location__address:hover {
  text-decoration: underline;
  cursor: pointer;
}

.experience-location {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.peculiarities__icons .MuiSvgIcon-root{

  width: .7em;

}
.peculiarities{
  display: flex;
  margin-top: 1rem;
}

.pec__explaining{
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
}

.peculiarities__resume {
  font-size: 12px;
  margin-left: 2px;
  color: #717171;
}

.peculiarities__title {
  font-weight: 800;
}
.peculiarities__title__green{
  color: #00A699;
  font-weight: 400;
  text-decoration: underline;

}

.description__listing{

  line-height: var(--html-text-line-height, 24px);
  word-break: break-word ;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-justify: left;
  border-top: 1px solid #f1f1f1;


}

.title{
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  max-height: 35px;
  margin-top: 2rem;
}

.rating__icon {
  width: .7em;
  padding-bottom: 3px;
  color: #ff2e76;
  margin-bottom: 2px;
}

.verified__icon{
  color: #ff2e76;
}

.peculiarities__title__primary{
  color: #ff2e76;
  font-weight: 800;
}


.description__title{
  font-weight: 600;
  font-size: 24px;
}

.experience-price{

  font-size: 20px ;
  line-height: 26px ;
  display: inline-block;
  font-weight: 700;

}

.map {

  display: flex;
  width: 300px;
  height: 200px;
  border-radius: 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.notification {
  margin: 1rem;
  border: 1px solid #717171;
  border-radius: 5px;
  padding: 1rem;
  text-justify: center;
  color: #717171;

}

.notification__icon {
  margin-right: 5px;
  color: #717171;
}

.container__listing{
  max-width: 1200px;
  margin: 0 auto;
  display: block ;

}

.img-carousel-portada{
  width: 100%;
  height: 100%;
  display: grid;
  grid-area: 1 / 1 / auto / auto;
  border-radius: 7px 0px 0px 7px;

}

.main-body{
  border: 1px solid red;
  max-width: 1200px;
}

.experience-carousel{
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr ;
  grid-gap: 5px;
  max-width: 1200px;
  padding: 1rem;
}

.container-middle{
  display: grid;
  grid-area: 1 / 2 / auto / auto;

}
.grid-middle{
  display: grid ;
  grid-auto-columns: 1fr ;
  grid-auto-rows: 1fr ;
  gap: 8px ;
  grid-template-columns: 1fr 1fr 1fr ;

}

.img-carousel-2{
  grid-area: 1 / 1 / span 2 / span 2;
  width: 100%;
  height: 100%;

}
.img-carousel-3{
  grid-area: 1 / 3 / auto / auto;
  width: 100%;
height: 100%;

}

.img-carousel-4{
  width: 100%;
height: 100%;

}

.img-carousel-5{
  width: 100%;
height: 100%;
border-radius: 0px 7px 7px 0px;

}

.container__file{
  margin: 0 auto;
}

.booking-container {
  margin: 1rem;
}

.button-booking{
  padding: 10px 20px;
  border: none;
  background-color: #ff2e76;
  border-radius: 5px;
  color: white;
  font-weight: 800;
  width: 100%;

}

.comments-experience{
  color: #00A699;
  border-bottom: 1px solid #00A699;
  font-weight: bolder;

}

.comment:hover{
  cursor: pointer;
}

.comments-container{
  display: flex;
  justify-content: space-around;
}


.experience-titles {
  max-width: 1200px;
  margin: 0 auto;
}



@media (min-width: 800px) {

  .experience__container {
    max-width: 50%;
  }

  .image__listing{
    width: 450px;
    height: 100%;
    max-width: 550px;
    border-radius: 10px;
  }
  .file__listing{
    display: flex;
    margin-top: 2rem;
  }

  .peculiarities__listing{
    height: 100%;
    border-radius: 5px;
  }

  .pricebox{
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 20px 20px 15px 20px;

    margin-top: 1rem;
  }

  .title__listing{
    margin-top: 1rem;

  }
  .peculiarities__resume{
    font-size: 10px;
  }

  .layout-description{
    width: 100%;
    padding: 1rem;
  }

 .container-description{
   display: block;
   max-width: 1200px;
 }

}

.layout__grid {
  width:100%;
  min-width: 330pxƒ;
  min-height: 500px;
}


@media (min-width: 700px) {
  .experience__container {
    display: block;
    margin: 0 auto;
    max-width: 66.666%;
    justify-content: left;

  }
  .container-description{
    display: flex;
  }

  .included{
    display:flex;
    margin-top: 10px;
  }


}

@media (min-width: 990px) {
  .container__file{
    width: 100%;
  }
}

@media (max-width: 900px) {
  .experience__container{
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .image__listing{
    max-width: 100%;
  }

  .title__listing{
    margin-top: 1rem;

  }

  .experience-carousel{

    display: flex;
    display: -webkit-flex;
    display: flex;
    overflow-x: scroll;
    padding: 10px;

  }
  .grid-middle{
    display: flex;

    }

    .img-carousel-portada{
      width: 330px;
      height: 100%
    }
    .img-carousel-portada, .img-carousel-2, .img-carousel-3, .img-carousel-4, .img-carousel-5{
      width: 330px;
      height: 100%
    }

    .container_pricebox{

      width: 100%;
      background-color: #fff;
      z-index: 10;
      padding: 5px;
      margin:0;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      -webkit-box-shadow: 0 6px 5px 7px rgba(170,170,170,0.75);
      -moz-box-shadow: 0 6px 5px 7px rgba(170,170,170,0.75);
      box-shadow: 0 6px 5px 7px rgba(170,170,170,0.75);
    }

    .pricebox{
      border:none;
      border-radius: 0px;
      padding: 0;
      margin-top:0;
      display: flex;
      justify-content: space-around;
    }


    .included, .number__rating, .experience-location {
      display: none;
    }




}