


.userimage{
  max-width: 3rem;
}

.comment__profile{
  display: flex;
  align-items: center;
}

.comment__card{
  padding: 1.5rem;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  min-height: 100%;

}

.comment__container{
padding: 1rem;
align-items: center;
justify-content: center;
}

/* .user__date{
  margin-top: 5px;
} */

 .comment__username{
  margin-bottom: 25px;
} 

/* .date{
  font-size: 12px;
} */

