*{
  font-family: 'Nunito', sans-serif;

}


.hero-subtitle {
  color: #fff;
  font-weight: 800;
  margin: 4rem 2rem 1rem 2rem;

}

.explorer-hero {
  margin-top: 2rem;

}

.container-titles {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

}


.hero-slogan {
  color: #fff;
  margin: 0 2rem  ;
  max-width: 500px;

}
.btn-rounded {
  margin: 2rem;
  display: flex;
  background-color: white;
  color: #484848;
  border: none;
  border-radius: 40px;
  padding: 20px 30px;
  font-size: 20px;
  -webkit-box-shadow: 0px 0px 42px 0px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 42px 0px rgba(255,255,255,1);
  box-shadow: 0px 0px 42px 0px rgba(255,255,255,1);
  font-weight: 600;

}

.btn-rounded:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;  color: webkit-linear-gradient(90deg, #6F019C 0%, #C6017E 135.12%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);

}

.main-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  position: relative;
  background: url('../../assets/backgrounds/landscape.jpg');

}

.grid-container {
  display: grid;
  width: 100%;
  padding: 1rem;
  grid-gap: 10px;
  column-gap: 10px;
  grid-template-areas:
    "main main"
    "foto2 foto3";
}

.item1 {
  grid-area: main;
  background-color: rebeccapurple;
  width: 100%;
  height: 350px;
  max-height: 231px;
  border-radius: 7px;
}

.item2 {
  grid-area: foto2;
  border-radius: 7px;
  width: 100%;
  height: 200px;
}

.item3 {
  grid-area: foto3;
  border-radius: 7px;
  width: 100%;
  height: 200px;
}

.item4 {
  grid-area: foto4;
  display: none;
}

.hero-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 7px;
}
@media (max-width: 600px) {


  .hero-subtitle {
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    max-width: 500px;

  }

  .container-titles {
    display: block;
    margin-top: 0;

  }

  .hero-slogan {
    display: flex;
    color: #fff;


  }

  .explorer-hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


  }
  .container-hero {
    display: flex-column;
    justify-content: center;
    align-items: center;
    margin-top: 40%;

  }


}

@media (min-width: 600px) {
  .hero-subtitle {
    display: flex;
  
    margin:0 1rem 0 1rem;
    font-weight: 600;
    max-width: 500px;

  }



  .container-titles {
    display: flex;
    justify-content: space-around;
  }

  .main-cont {
    width: 100%;

  }



  .grid-container {
    display: grid;
    width: 100%;
    padding-left: 2rem;
    justify-content: center;
    grid-gap: 5px;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "main foto2 "
      "main foto3";
    background-color: #000;
    padding-bottom: 4rem;
    padding-right: 2rem;
  }

  .item1 {
    grid-area: main;
    width: 100%;
    height: 350px;
    max-height: 600px;
  }

  .item2 {
    grid-area: foto2;
    width: 100%;
    height: 100%;
  }

  .item3 {
    grid-area: foto3;
    width: 100%;
    height: 100%;
  }
  .item4 {
    display: none;
  }
}

@media (min-width: 760px) and (max-width: 900px) {

  .hero-subtitle {
    display: flex;
    font-weight: 600;
    max-width: 500px;
    margin:0 1rem 0 1rem;

  }


}

@media (min-width: 900px) {
  .main-cont {
    padding-left: 50px;
    padding-right: 50px;
  }
  .grid-container {
    grid-template-areas:
      "main foto2 foto3"
      "main foto4 foto4";
  }
  .item1 {
    grid-area: main;
    width: 100%;
    height: 100%;
  }

 

  .item2 {
    grid-area: foto2;
    width: 100%;
  }
  .item3 {
    grid-area: foto3;
    width: 300px;
    height: 100%;
  }

  .item4 {
    display: grid;
    grid-area: foto4;
    width: 100%;
    max-height: 500px;
  }

  .hero-subtitle {
    display: flex; 
    font-weight: 600;
    max-width: 500px;
    margin:0 1rem 0 1rem;

  }

  
}

@media (min-width: 1500px) {
  .item1 {
    height: 100%;
  }

  .main-cont {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media (min-width: 1200px) {
  .hero-subtitle {
    display: flex;
    margin-top: 1rem;
    font-weight: 600;
    max-width: 500px;

  }


}



