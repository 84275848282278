

.title-listing {
  margin-left: 2.5rem;
  color: #0d0c22;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.dropdown-filter:hover {
  border: 3px solid #ff2e76 !important; 
}

.experience-hero {
  margin: 2rem 0 0 2rem ;
  display: flex ;
  overflow: auto hidden ;
  scroll-snap-type: x mandatory;
  gap: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;

}

::-webkit-scrollbar {
  display: none;
}


.dates-listing {
  display: flex;
  flex-direction: column;
}
.schedule {
  margin-left: 5px;
}

.card-listing {
  width: 243px;
  margin-left: 10px;
}

.experience-image img {
  width:300px;
  border-radius: 5px;
  margin-bottom: 10px;
  object-fit: cover;
  height: 400px;
}
.category-listing {
  background-color: none;
  border: 1px solid #0d0c22;
  border-radius: 5px;
  padding: 3px 5px;
  text-transform: uppercase;
  color: #0d0c22;
  font-weight: bold;
  max-width: 100%;
  font-size: 12px;
  margin-left: 5px;
}

.slogan-listing {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  overflow: hidden;
}
.name-listing {
  font-weight: 500;
  font-size: 16px;
  color: #0d0c22;
  margin-left: 5px;
}

.category-hours{
  display: flex;
  justify-content: space-between;
}

.experience-info{
  max-width: 300px;
}

.titcat {
  display: flex;
  justify-content: space-between;
}

.timeicon {
  margin-right: 5px;
  object-fit: contain;
  height: 15px !important;
}

.title__nav{
  font-weight: 700;
}

.subnav__items{
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  padding-top: 15px;
}

.nav__icon .MuiSvgIcon-root:hover{
  color: #ff2e76;
}

.nav__item{
  text-decoration: none;
}

.navigation__link{
  color: rgb(15, 20, 25);
  margin-left: 15px;
  font-weight: 800;
  text-align: inherit;
  list-style: none;
  background-color: transparent;
  padding: 10px 15px;
  border-radius: 50px;

}

.navigation__link:hover {
  background-color: #FBFBFB;
  border-radius: 50px;
  padding: 10px 15px;
  text-decoration: none;
  color: #000;

}

.nav__list{

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.subnav{
  align-items: center;
}

.plan {
  display: flex;
  align-items: center;
  width: 100%;

}

.plan:hover{
  cursor: pointer;
}

.plan__categoryImage{
  border-radius: 10px;
  width: 72px;
}


.plan__title {
  font-size: 1.1em;
  font-weight: 600;
  padding: 0;
  margin-bottom: 0;

}

.plan__content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}


@media (min-width: 300px) and (max-width: 600px){

.card-listing {
  margin-left: 0;

}

.title__nav {
  display: none;
}
.nav__icon .MuiSvgIcon-root:hover{
  color: #000;
}

.navigation__link{
  margin-left: 0;
}
}

@media (min-width: 600px) {
  .nav__icon .MuiSvgIcon-root:hover{
    color: #000;
  }
}

@media (min-width: 768px) {

    .image__listing{
      width: 300px;

    }
  }






