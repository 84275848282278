.bg-light {
  background-color: white;
  border-bottom: 1px solid #f1f1f1;
}

.subnav {
  display: flex !important;
  justify-content: space-evenly !important;
}

.nav-link {
  color: #6e6d7a !important;
  margin-left: 6px !important;
  font-weight: 600 !important;
  color: #333 !important;
  font-size: 15px;
}

.nav-link:hover {
  color: #333 !important;
}

.btn-secondary {
  background-color: transparent !important;
  color: #6e6d7a !important;
  border-radius: 8px !important ;
  box-sizing: border-box !important;
  border: 3px solid #797982 !important;
}

.btn-secondary:hover {
  border-radius: 8px !important;
  border: 3px solid #FF2E76 !important;
  box-sizing: border-box !important;
  outline: none !important;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none !important;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-item {
  font-weight: medium !important;
  font-size: 14px;
  color: #3e3e46 !important;
}


@media only screen 
    and (min-width : 330px)  {
      .nav-link {
        margin-left: -10px !important;

      }
     }