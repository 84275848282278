.container-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin-top: 4rem;
  list-style: none;
  background-color: #fcfcfc;
  margin: 0 auto;
  position: relative;
  left: 0;
  bottom: 0;
}

.footer-list {
  list-style: none;
  font-weight: bold;
  margin-left: -30px;
}
.logo-footer {
  max-width: 150px;
}

.footer-title {
  font-weight: 600;
}

.what-is {
  max-width: 350px;
font-size: 15px;
  text-justify: justify;
}

.img-lang{
  width: 2rem;
}

.lang-container{
  display:flex;
  justify-content: center;
}

.lang-btn:hover{
cursor: pointer;
}

a .MuiSvgIcon-root {
  color: #484848;
}

@media (max-width: 600px) {
  .container-footer {
    display: block;
    width: 100%;
  }
}
