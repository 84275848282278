.table-listing-list {
  display: flex;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}

.table-listing-title {
  margin-bottom: 1.5rem;
}

.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.table-row_listing {
  margin-bottom: 1.5rem;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 4px 2px 5px 0px rgba(0,0,0,0.28);
  -moz-box-shadow: 4px 2px 5px 0px rgba(0,0,0,0.28);
  box-shadow: 4px 2px 5px 0px rgba(0,0,0,0.28);
}

.container-table {
  padding: 1rem;
  display: flex;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  flex-direction: column;
}
.table-listing-name{
  margin-top: 2px;
  word-wrap: break-word;
}
.table-listing-img {
  width: 160px;
  height:112px;
  margin-left: 10px;
}

.table-item-listing{
  max-width: 340px;
}

.btn__edit {
  border: 1px solid #008489;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: transparent;
  color : #008489;

}

.btn__delete{
  border: 1px solid #ff2e76;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: transparent;
  color : #ff2e76;
}
