
.logo{
  width: 150px;
}

.btn-itineramio {
  color: #fff;
  padding: 8px 15px;
  border-radius: 8px;
  background-color: #ff2e76;
  border: none;
  font-weight: 600;
}

  .btn-itinevip {
    color: #6e6d7a;
    padding: 5px 15px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid #6e6d7a;
    font-weight: 600;
  }

  .btn-itinevip:hover {
    cursor: pointer;
    border: 1px solid #ff2e76;
    color: #ff2e76;
  }
  #searcher:focus {
    border: 1px solid red;
  }

  .header__transparent{
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px !important;
  }

  #dropdownMenuButton{
    color: #484848;
    padding: 4px;

  }
  #dropdownMenuButton:focus{
    outline: none !important;

  }

  #dropdownMenuButton:active{
    outline: none !important;

  }


  @media (max-width: 600px) {
    .form-control {
      display: none !important;
    }
  }
  @media (max-width: 991px) {
    .btn-itinevip{
      margin-left: 10px;
      margin-top: 10px;
    }
    }