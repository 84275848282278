.card-img-top{
  max-width: 300px;
  max-height: 130px;
}

.form-listing, .form-experience {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;


}
.control-panel{
  width: 100%;
  display: flex;
  justify-content: center;

}

.prices-group{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.labels__listing {
  margin-left: 5px;
}

.form-card{
  min-width: 350px;
  width: 100%;
  max-width: 1200px;
  padding: 1rem ;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin-top: 1rem;
}

.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 48px;
	width: 480px;
	padding: 0 8px;
	border: 1px solid rgb(214, 216, 218);
	border-radius: 6px;
	&:focus-within {
		border: 1px solid #0052cc;
	}
	input {
		flex: 1;
		border: none;
		height: 46px;
		font-size: 14px;
		padding: 4px 0 0 0;
		&:focus {
			outline: transparent;
		}
	}
}

#tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 8px 0 0 0;
}

.tag {
	width: auto;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 6px;
	margin: 0 8px 8px 0;
  background: #0052cc;
}
	.tag-title {
		margin-top: 3px;
	}
	.tag-close-icon {
		display: block;
		width: 16px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		font-size: 14px;
		margin-left: 8px;
		color: #0052cc;
		border-radius: 50%;
		background: #fff;
		cursor: pointer;
  }


  .phonecontainer{
    max-width: 200px;

  }



  @media (max-width: 600px) {
    .form-control{
      display: block !important;
    }
  }