.header__about{
  width: 100%;
}

.slogan__banner{
  justify-content: center;
  text-justify: center;
  font-weight: 600;
}

.cta__banner{
  -webkit-box-align: center ;
  -ms-flex-align: center ;
  -webkit-box-direction: normal ;
  -webkit-box-orient: vertical ;
  position: absolute ;
  top: 0px ;
  left: 0px ;
  right: 0px ;
  display: -webkit-box ;
  display: -moz-box ;
  display: -ms-flexbox ;
  display: -webkit-flex ;
  display: flex ;
  -webkit-flex-direction: column ;
  -ms-flex-direction: column ;
  flex-direction: column ;
  -webkit-align-items: center ;
  align-items: center ;
  text-align: center ;
  padding: 0 24px ;
  margin-top: 77px ;

}

.bullets{
  display: flex;
  justify-content: space-around;
  padding: 2rem;
  width: 100%;
  flex-wrap: wrap;
}

.bullets__img {
  max-width: 330px;

}

.bullet{
  justify-content: center;
  text-align: center;
  max-width: 330px;
}


.slogan__container{
  width: 100%;
  }


.image__banner {
  width: 100% ;
  object-fit: cover ;
  max-height: 680px ;

}
.image__banner-big {
  display: none;

}

.title__banner {
  font-size: 35px ;
  font-weight: 700 ;
  margin-top: 3rem;

}

.cab {
position: absolute;
  padding: 1rem;
  width: 100%;
}

.logo_banner{
  width: 40px;
}

.about__us{
display: flex;
justify-content: center;
text-align: center;
flex-direction: column;
padding: 2rem 2rem 0 2rem;
}


.about__title {
  font-weight:700;
}

.contact_container{
  width: 100%;
  height: 100%;
  background-color: #FCFCFC;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items:center;
}

.contact-titles{
  display: flex;
  flex-direction: column;
  max-width: 600px;
}


.contact__title{
  font-weight: 700;
  font-size: 30px;
}

.contact__email{
  font-weight: 700;
  font-size: 20px;

  padding: 10px 0 10px 0 ;
}


  .btn-rounded-hiw {
    margin-top: 1rem;
    display: flex;
    background-color: transparent;
    color: white;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 10px 15px;


}

@media (min-width: 600px) {
  .contact_container{
    width: 100%;
    height: 100%;
    background-color: #FCFCFC;
    padding: 3rem;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items:center;
  }

  .image__banner-big{
    width: 100% ;
    object-fit: cover ;
    max-height: 680px;
    display:block;
  }
  .image__banner{
    display: none;
  }

}

@media (min-width: 900px) {

.slogan-bullet{
  padding-top: 1rem;
  width: 60%;
  text-align: center;
  justify-content: center;
  margin: 0 auto;


}
}