.estandares-layout{
 
  justify-content: center;
  display: flex;
  padding: 1rem;
}

.estandares-grid{
  display: flex;
  justify-content: center;
}

.estandares-btn {
  cursor: pointer ;
    display: inline-block ;
    margin: 0px ;
    position: relative ;
    text-align: center ;
    text-decoration: none ;
    width: auto ;
    touch-action: manipulation ;
    font-weight: 600 ;
    font-size: 14px ;
    line-height: 18px ;
    letter-spacing: normal ;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif ;
    color: rgb(72, 72, 72) ;
    padding: 9px 16px ;
    border: 0px ;
    background: rgb(255, 255, 255) ;
    border-radius: 8px ;
}


.estandares-container {
  z-index: 0 ;
  background: rgb(72, 72, 72) ;
  border: 0px none ;
  display: flex ;
  overflow: hidden ;
  position: relative ;
  text-align: left ;
  text-decoration: none ;
  border-radius: 16px ;
  justify-content: center;
}

.estandares-cta {
  padding: 2rem;
  max-width: 344px ;
  color: white;
  position: absolute;
  left: 10px;

}

.estandares-title {
  font-size: 30px ;
  font-weight: bolder;

}

.estandares-description {
  font-size: 18px ;
  max-width: 260px;

}


.stand-img2{
  display:none
}

.stand-img{

width: 100%;
min-height:250px;
}


@media (max-width: 662px) {
  .estandares-container {
    background-color: #000;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .stand-img2{
    display:block
  }

  .stand-img{
    display: none;
    min-width: 250px;

    }

  .estandares-cta {

    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 470px;

  }

  .estandares-description {
    font-size: 18px ;
     max-width: 600px;

  }

}



@media (max-width: 512px) {
  .estandares-cta{
  width: 100%;
  height: auto;
  }

  .estandares-container{
    width: 100%;

  }

  .estandares-layout{
    width: 100%;
  }

  .stand-img2{
    width: 100%;

  }

  .estandares-title {
    font-size: 25px ;
  
  }

  .estandares-description {
    font-size: 16px ;
    min-width: 230px;
  }
}
